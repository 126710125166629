<template>
  <div class="extension-notifications">
    <page-subnav class="extension-notifications__subnav">
      <notifications-list-params
        class="extension-notifications__subnav-action"
        :value="{ sort: $route.query.sort || '' }"
        @input="onListParamsChange"
      />
    </page-subnav>

    <notifications-list
      class="extension-notifications__list"
      group-by="createdAt"
      @update-list-ask="loadNotifications"
    />
  </div>
</template>

<script>
import PageSubnav from 'Common/PageSubnav'
import NotificationsList from './components/NotificationsList'
import NotificationsListParams from './components/NotificationsListParams'

import { mapActions, mapGetters } from 'vuex'
import { extensionNotificationsActions } from './store/types'
import { bellGetters } from 'Store/entities/Bell/types'

import { EXTENSION_TOPICS } from 'Constants/notificationTopics'
import {
  NOTIFICATIONS_DEFAULT_SORT,
  NOTIFICATIONS_SORT_TO_ORDER_MAP
} from './constants'

import { notificationsClient, NOTIFICATIONS_CLIENT_EVENTS } from 'Services/notificationsClient'
import { Notification } from 'Models/Notification'

import { showError } from 'Utils/notifications'
import isEqual from 'lodash/isEqual'

export default {
  name: 'extension-notifications',
  components: {
    PageSubnav,
    NotificationsList,
    NotificationsListParams
  },

  computed: {
    ...mapGetters('entities/bell', {
      extensionCount: bellGetters.EXTENSION_UNREAD_COUNT,
    }),
  },

  created () {
    notificationsClient.on(
      NOTIFICATIONS_CLIENT_EVENTS.NOTIFICATION,
      this.onNotificationEvent
    )

    this.loadNotifications()
  },

  beforeDestroy () {
    notificationsClient.off(
      NOTIFICATIONS_CLIENT_EVENTS.NOTIFICATION,
      this.onNotificationEvent
    )
  },

  methods: {
    ...mapActions('ui/extension-notifications', {
      loadNotificationsAction: extensionNotificationsActions.LOAD_NOTIFICATIONS,
      unshiftNotifications: extensionNotificationsActions.UNSHIFT_NOTIFICATIONS,
    }),

    onNotificationEvent (data) {
      const notification = new Notification(data)
      if (notification.isRead) return
      if (!EXTENSION_TOPICS.includes(notification.topic)) return

      this.unshiftNotifications(data)
    },

    onListParamsChange (input = {}) {
      const query = { ...this.$route.query }

      query.sort = input.sort === NOTIFICATIONS_DEFAULT_SORT
        ? undefined
        : input.sort || undefined

      if (!isEqual(query, this.$route.query)) {
        this.$router.push({ query })
      }

      this.loadNotifications()
    },

    async loadNotifications () {
      const query = this.$route.query
      const sort = query.sort || NOTIFICATIONS_DEFAULT_SORT

      try {
        await this.loadNotificationsAction({
          page: { order: NOTIFICATIONS_SORT_TO_ORDER_MAP[sort] },
          filter: { topic: EXTENSION_TOPICS.join() },
        })
      } catch (error) {
        if (!error.isCanceled) {
          showError(this.$t('LIST_FETCH_FAILED_NOTIFY'))
          console.error(error)
        }
      }
    },
  },

  metaInfo () {
    return {
      title: this.$t('META_TITLE'),
      titleTemplate: `%s | ${this.$t('COMMON.APP_TITLE')}`,
    }
  }
}
</script>

<style lang="scss" scoped>
.notifications {
  display: flex;
  flex-direction: column;
  flex: 1;

  // override vue-notification’s ".notifications" styles
  position: unset;
  z-index: unset;
}
</style>

<i18n>
{
  "en": {
    "META_TITLE": "Notifications",
    "LIST_FETCH_FAILED_NOTIFY": "Cannot load the notifications list. Please try again later or contact the system owner."
  },
  "ka": {
    "META_TITLE": "შეტყობინებები",
    "LIST_FETCH_FAILED_NOTIFY": "შეტყობენებების სია ვერ ჩაირტვირთა. დაუკავშირდით ადმინისტრატორს"
  },
  "ru": {
    "META_TITLE": "Оповещения",
    "LIST_FETCH_FAILED_NOTIFY": "Не удалось загрузить список оповещений. Повторите попытку позже или свяжитесь с владельцем системы."
  },
  "uk": {
    "META_TITLE": "Сповіщення",
    "LIST_FETCH_FAILED_NOTIFY": "Не вдалось завантажити список сповіщень. Повторіть спробу пізніше або зв’яжіться з власником системи."
  }
}
</i18n>
