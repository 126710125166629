<template>
  <list-grouped
    class="notifications-list"
    :list="notifications"
    :list-total-count="0"
    group-by-date-field="createdAt"
    :is-loading="isLoading"
    :is-next-loading="isNextLoading"
    :page-size="NOTIFICATIONS_PAGE_LIMIT"
    :load-more="loadMore"
    :item-component="NotificationItem"
    :item-skeleton-component="NotificationItemSkeleton"
    :reset-on-change="$route.params.tab"
    @update-list-ask="$emit('update-list-ask')"
  />
</template>

<script>
import ListGrouped from 'Common/ListGrouped'
import NotificationItem from './NotificationItem'
import NotificationItemSkeleton from './NotificationItemSkeleton'

import { mapGetters, mapActions } from 'vuex'
import {
  extensionNotificationsGetters,
  extensionNotificationsActions
} from '../store/types'

import { NOTIFICATIONS_PAGE_LIMIT } from '../constants'

export default {
  name: 'notifications-list',
  components: { ListGrouped },

  data () {
    return {
      NotificationItem,
      NotificationItemSkeleton,
      NOTIFICATIONS_PAGE_LIMIT,
    }
  },

  computed: {
    ...mapGetters('ui/extension-notifications', {
      notifications: extensionNotificationsGetters.LIST,
      isLoading: extensionNotificationsGetters.IS_LOADING,
      isNextLoading: extensionNotificationsGetters.IS_NEXT_LOADING,
    }),
  },

  methods: {
    ...mapActions('ui/extension-notifications', {
      loadMore: extensionNotificationsActions.LOAD_MORE,
    }),
  },
}
</script>

<style lang="scss" scoped>
.notifications-list {
  --list-padding-side: 3em;
}
</style>
